import {useEffect} from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import env from 'constants/env';
import {DemoAccountMutationError} from 'constants/errors';
import {OAuthError} from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import appPackage from '../../package.json';
import isLocalHost from 'utils/environment-utils/is-local-host';

Sentry.init({
  enabled: Boolean(env.VITE_SENTRY_DSN),
  dsn: env.VITE_SENTRY_DSN,
  environment: env.VITE_ENVIRONMENT_NAME,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  release: appPackage.version,
  debug: isLocalHost,
  beforeSend(event, hint) {
    const error = hint.originalException;

    if (error && error instanceof DemoAccountMutationError) {
      return null;
    }

    if (
      error &&
      error instanceof OAuthError &&
      error.message?.includes('Login required')
    ) {
      return null;
    }

    return event;
  },
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
