import {PersonaType} from 'interfaces/personality-profile';
import {FullUserProfile, UserProfile} from 'interfaces/user-profile';

export const dummyUserProfile: FullUserProfile = {
  connectedCalendars: [],
  companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
  fullOnboardingChecklist: {
    skippedUserInvite: false,
    hasAnyPersonalActionStep: true,
    hasCompletedProfile: true,
    hasCreatedValue: true,
    hasCreatedProfile: true,
    hasAddedFirstMeeting: true,
    hasSignedOff: true,
    hasAddedCompanyValues: true,
    hasConfiguredAssessment: true,
    hasCreatedCultureKpi: true,
    hasGeneratedMeetingReport: true,
    hasInvitedCandidates: true,
    hasInvitedFirstUser: true,
    hasSeenFirstMeetingReport: true,
    hasSelectedBusinessOutcomes: true,
    hasSharedFirstMeetingReport: true,
    hasSyncedCalendar: true,
    hasTakenPersonalityTest: true,
  },
  isOnboarded: false,
  funFact:
    'One quirky detail about me is that I once organized a surprise flash mob dance at a company event, and it turned out to be an unforgettable and uproarious success!',
  jobTitle: 'CEO',
  kudosRemaining: 20,
  personalEmail: null,
  kudosGivenCount: 0,
  kudosReceivedCount: null,
  percentageComplete: 30,
  linkedinUrl: null,
  location: null,
  name: 'Jane Doe',
  phoneNumber: '+957988232005',
  picture: '/static/demo-account/profile.jpeg',
  preferredName: 'Jane Doe',
  pronouns: null,
  role: 'owner',
  shortBio:
    "I'm a PM with a knack for orchestrating chaos into seamless projects. My life revolves around managing tasks and transforming them into success stories. When I'm not at work, I'm either conquering mountains on hikes.",
  status: 'joined',
  timeZone: 'Asia/Calcutta',
  uuid: '76026980-81d0-4180-a3fa-05a6215ce063',
  workEmail: 'jane.demo@instill.ai',
  team: {
    uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
    teamSize: 2,
    name: 'Product Team',
    description: '',
    colorHex: '#fefefe',
    teamManagers: [
      {
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        name: 'Jane Doe',
      },
    ],
  },
  manager: null,
  recruits: [],
  personalityProfile: {
    agreeableness: 93,
    conscientiousness: 84,
    emotionality: 100,
    extroversion: 80,
    honesty: 86,
    openness: 75,
    persona: PersonaType.Enigma,
    personaContent: {
      name: 'The Enigma',
      summary:
        'The Enigma is a complex personality type with a unique blend of assertiveness, adaptability, and analytical thinking.',
      traits: ['Assertive', 'Adaptive', 'Analytical'],
      description: {
        header: 'Excelling in social situations',
        content:
          'The Enigma is highly assertive and excels in social situations. They have a natural ability to take charge and confidently express their opinions and ideas. Their adaptability allows them to easily navigate different social dynamics and adjust their behavior accordingly. With their analytical thinking, they are able to quickly assess situations and make informed decisions, making them a valuable asset in group settings.',
      },
      deepDive: {
        headline: "The Enigma's Inner World",
        content:
          'The Enigma possesses a deep and intricate inner world. They are constantly analyzing their surroundings and processing information in a unique and thoughtful manner. This deep thinking often leads them to come up with innovative solutions and ideas. However, their introspective nature can sometimes make them seem distant or aloof to others. It is important for others to understand that this is simply their way of processing information and should not be mistaken for disinterest or indifference.',
      },
      wellFunctioningGroup: {
        content:
          "The Enigma thrives in a well-functioning group. They are assertive and confident in expressing their ideas and opinions, which helps drive discussions and decision-making processes. Their adaptability allows them to easily collaborate with different personalities and find common ground. The Enigma's analytical thinking enables them to contribute valuable insights and perspectives, making them a valuable team member. To succeed in a well-functioning group, The Enigma should continue to assert themselves while also actively listening to others and considering different viewpoints.",
      },
      outOfSync: {
        content:
          "When out of sync with others, The Enigma may struggle to effectively communicate their thoughts and ideas. Their assertiveness can sometimes come across as overly dominating or dismissive, leading to conflicts within the group. It is important for The Enigma to be mindful of their communication style and actively seek feedback from others. Additionally, their deep thinking and introspective nature may cause them to become isolated or withdrawn when faced with disagreements. To overcome this, The Enigma should actively engage in open and honest communication, actively seek to understand others' perspectives, and find ways to compromise and collaborate effectively.",
      },
    },
    personalValues: {
      selfDirection: 67,
      stimulation: 48,
      hedonism: 57,
      achievement: 57,
      power: 57,
      security: 57,
      conformity: 57,
      tradition: 57,
      benevolence: 57,
      universalism: 57,
      userPersonalValues: [
        {
          title: 'Compassionate Leadership',
          description:
            "In the realm of my ideal society, compassionate leadership is the cornerstone. It's not about dominion, but about guiding with empathy and understanding. I value the gentle strength in leading others, ensuring that every voice is heard and every heart is considered.",
        },
        {
          title: 'Emotive Connection',
          description:
            'I see a world where deep emotional bonds are the fabric that holds us together. My value of emotive connection is about cherishing these ties, recognizing that our shared feelings are a source of collective strength and joy.',
        },
        {
          title: 'Harmonious Coexistence',
          description:
            "Harmonious coexistence is the heartbeat of my perfect community. It's a value that resonates with my vision of a society where each individual's rhythm complements the other, creating a symphony of peaceful collaboration and mutual respect.",
        },
        {
          title: 'Ethical Integrity',
          description:
            'A society flourishes when rooted in ethical integrity. This personal value is a reflection of my belief in honesty and moral clarity as the guiding principles of every action we take, both as leaders and as members of the community.',
        },
        {
          title: 'Diligent Stewardship',
          description:
            "Diligent stewardship represents my commitment to conscientious management of the world around us. It's about taking responsibility, not just for the present, but for the legacy we leave for future generations, ensuring our actions today foster a sustainable tomorrow.",
        },
        {
          title: 'Inclusive Wisdom',
          description:
            'In a perfect society, inclusive wisdom is the light that leads us forward. I value the diversity of thought and experience, as it is through embracing our collective insights that we can make the wisest decisions for the benefit of all.',
        },
      ],
    },
  },
  reporters: [],
  workStylePreferences: null,
  settings: {
    senseAutoJoin: false,
  },
};

export const dummyManagers: UserProfile[] = [
  {
    connectedCalendars: [],
    companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
    fullOnboardingChecklist: {
      skippedUserInvite: false,
      hasAnyPersonalActionStep: true,
      hasCompletedProfile: true,
      hasCreatedValue: true,
      hasAddedFirstMeeting: true,
      hasCreatedProfile: true,
      hasSignedOff: true,
      hasAddedCompanyValues: true,
      hasConfiguredAssessment: true,
      hasCreatedCultureKpi: true,
      hasGeneratedMeetingReport: true,
      hasInvitedCandidates: true,
      hasInvitedFirstUser: true,
      hasSeenFirstMeetingReport: true,
      hasSelectedBusinessOutcomes: true,
      hasSharedFirstMeetingReport: true,
      hasSyncedCalendar: true,
      hasTakenPersonalityTest: true,
    },
    isOnboarded: false,
    funFact: null,
    jobTitle: 'Product Manager',
    kudosRemaining: 20,
    personalEmail: null,
    kudosGivenCount: null,
    kudosReceivedCount: null,
    percentageComplete: 0,
    linkedinUrl: null,
    location: 'Delhi, India',
    name: 'Madhvendra Tiwari',
    phoneNumber: '+917073175130',
    picture:
      'https://instill-app-api-beta.s3.amazonaws.com/companies/03b3998e-e55c-4544-88e6-015f18af850b/user-pictures/bdc4bdb6-99bc-4b24-91be-236363c9ff55.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQRMCGQ5UKWN5ZJUH%2F20240320%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240320T114251Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEPf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIGGIkR%2B15KPCv%2Ft67dF8UgC2HWDyHCbqfZW%2BM71YNi%2F0AiBMeZ5ip45vtzkv%2B7SvUHDYdSKNaSjYucT4BEgEHvt04Cr4Awj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAIaDDAzNzMxNzE1MDU2OCIMC9bZLtEiJ1wFYRatKswDqDrZFg%2FrLRFtK%2Bt%2BMpMAfveXo9iW0FPnXyI7ogGX0MrNw0UdV47w7N%2BpGSNZm%2Bccg3GiSdGxX%2BpDYax1RyVy%2B8RlmZHPAN%2Bv6GEqXxZstirYl3LDI0J0YXzByUfcBZ9h5noLyK2jN7teTpzsBIYNtHBFQBT0fGbUK2%2B4cStGCPaItO1ZePZB0K%2Bkipt3v3TvtOJz7zNt1BPWI%2B8IhrgmU8avEwnfpdUzq1%2FUWScGSGmNzn635WY2lLDAzPyPlRBahSnSfw7biKmbqufQaqrFlBnzqwB6S0yORplpYqVz86rv%2BzrepYUnuOp0CnNt7S9cloLDbUx2IDlhurJa3h0Ze6TI%2FOi7dE1VIpHfPgdayGAmyeOUSh2DLuGTW1Uxmx0VTQw0SrbSJ0n%2F%2FO5TFOlEB8TL%2F4IEWCxK%2B8rooKnaOsb7H4LePN2Mjbmu9uVBBGIM3U8%2FTiPUVWk3E%2FygiZID3ZBjNDaUBrfJwzSEY1iiDJouzA%2BlzFtj6eVUjNAnMdOfi5NzgBIZ%2FqUYAMqeQ9JAxtg5DTjpcPLit%2Fi65dS6pi7Hq8t%2Ff06f1VJwnduwOhFQOq9OmOOs2XNjF1%2FwRsHLYEY4wUBSgl3L8IfQozDe%2FOmvBjqmAYFR%2Boay7L4h8eRD%2F0OeO%2BiY82ElpHIubDpmcrdPl5NYpWVU0H2rvce2HoX9s3zNgECGN6zi3EI6e4XwgQnf9V8741RflWgwWjFjInvo7%2FdN6kiZjHWKJYjPCQWK5I1Bx9k2RCnLWgOF2PQpSaojEmGS3sJL03Acxcw%2BcTmyrF%2BPpdLYvppEysPwAEBIFrq%2BRH%2Bqs3KPrTgpRXxZjOchCxKIIKJCmOk%3D&X-Amz-Signature=03b815ec823f261d3d807c35d8e4db6876ec111ed3dc323980a82b9ee75c60ad',
    preferredName: null,
    pronouns: 'he-him',
    role: 'admin',
    shortBio: null,
    status: 'joined',
    timeZone: null,
    uuid: '6a5eb455-771e-46e9-a0b3-f45030dcc818',
    workEmail: 'madhvendra@instill.ai',
  },
  {
    connectedCalendars: [],
    companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
    fullOnboardingChecklist: {
      skippedUserInvite: false,
      hasAnyPersonalActionStep: true,
      hasCompletedProfile: true,
      hasCreatedValue: true,
      hasCreatedProfile: true,
      hasAddedFirstMeeting: true,
      hasSignedOff: true,
      hasAddedCompanyValues: true,
      hasConfiguredAssessment: true,
      hasCreatedCultureKpi: true,
      hasGeneratedMeetingReport: true,
      hasInvitedCandidates: true,
      hasInvitedFirstUser: true,
      hasSeenFirstMeetingReport: true,
      hasSelectedBusinessOutcomes: true,
      hasSharedFirstMeetingReport: true,
      hasSyncedCalendar: true,
      hasTakenPersonalityTest: true,
    },
    isOnboarded: false,
    funFact: null,
    jobTitle: null,
    kudosRemaining: 20,
    personalEmail: null,
    kudosGivenCount: null,
    kudosReceivedCount: null,
    percentageComplete: 0,
    linkedinUrl: null,
    location: null,
    name: 'paras@instill.ai',
    phoneNumber: null,
    picture: null,
    preferredName: null,
    pronouns: null,
    role: 'manager',
    shortBio: null,
    status: 'invited',
    timeZone: null,
    uuid: 'b8239ca3-56cd-449a-9989-433339011af2',
    workEmail: 'paras@instill.ai',
  },
  {
    companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
    connectedCalendars: [],
    fullOnboardingChecklist: {
      skippedUserInvite: false,
      hasAnyPersonalActionStep: true,
      hasCompletedProfile: true,
      hasCreatedValue: true,
      hasCreatedProfile: true,
      hasSignedOff: true,
      hasAddedCompanyValues: true,
      hasConfiguredAssessment: true,
      hasCreatedCultureKpi: true,
      hasGeneratedMeetingReport: true,
      hasInvitedCandidates: true,
      hasAddedFirstMeeting: true,
      hasInvitedFirstUser: true,
      hasSeenFirstMeetingReport: true,
      hasSelectedBusinessOutcomes: true,
      hasSharedFirstMeetingReport: true,
      hasSyncedCalendar: true,
      hasTakenPersonalityTest: true,
    },
    isOnboarded: false,
    funFact: null,
    jobTitle: 'Senior Backend Developer',
    kudosRemaining: 20,
    personalEmail: null,
    kudosGivenCount: null,
    kudosReceivedCount: null,
    percentageComplete: 0,
    linkedinUrl: null,
    location: null,
    name: 'philip@instill.ai',
    phoneNumber: '+2348114942778',
    picture: null,
    preferredName: 'Philip',
    pronouns: null,
    role: 'manager',
    shortBio: null,
    status: 'joined',
    timeZone: 'Africa/Lagos',
    uuid: 'eb03c861-1026-4028-889e-8e006b1b4cab',
    workEmail: 'philip@instill.ai',
  },
  {
    kudosGivenCount: 0,
    companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
    connectedCalendars: [],
    fullOnboardingChecklist: {
      skippedUserInvite: false,
      hasAnyPersonalActionStep: true,
      hasCompletedProfile: true,
      hasCreatedValue: true,
      hasCreatedProfile: true,
      hasSignedOff: true,
      hasAddedFirstMeeting: true,
      hasAddedCompanyValues: true,
      hasConfiguredAssessment: true,
      hasCreatedCultureKpi: true,
      hasGeneratedMeetingReport: true,
      hasInvitedCandidates: true,
      hasInvitedFirstUser: true,
      hasSeenFirstMeetingReport: true,
      hasSelectedBusinessOutcomes: true,
      hasSharedFirstMeetingReport: true,
      hasSyncedCalendar: true,
      hasTakenPersonalityTest: true,
    },
    isOnboarded: false,
    funFact: null,
    jobTitle: null,
    kudosRemaining: 20,
    personalEmail: null,
    kudosReceivedCount: null,
    percentageComplete: 0,
    linkedinUrl: null,
    location: null,
    name: 'sonya@dreamdevelopment.com',
    phoneNumber: null,
    picture: null,
    preferredName: null,
    pronouns: null,
    role: 'manager',
    shortBio: null,
    status: 'invited',
    timeZone: null,
    uuid: '835b07b8-30ad-4602-b66f-35fae5290210',
    workEmail: 'sonya@dreamdevelopment.com',
  },
];
