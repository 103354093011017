import {ButtonHTMLAttributes, ComponentProps} from 'react';
import styles from './styles.module.scss';
import {Link} from 'react-router-dom';

// Constants
const VARIANT_PRIMARY = 'primary';
const VARIANT_SECONDARY = 'secondary';
const VARIANT_SECONDARY_2 = 'secondary2';
const VARIANT_TERTIARY = 'tertiary';
const VARIANT_TERTIARY_INVERSED = 'tertiaryInversed';
const VARIANT_TERTIARY_BLACK = 'tertiaryBlack';
const VARIANT_TERTIARY_ALERT = 'tertiaryAlert';
const GRAY_VARIANT = 'gray';
const VARIANT_PRIMARY_GRADIENT = 'primaryGradient';
const VARIANT_DARK = 'dark';

export const ButtonVariants = [
  VARIANT_PRIMARY,
  VARIANT_SECONDARY,
  VARIANT_SECONDARY_2,
  VARIANT_TERTIARY,
  VARIANT_TERTIARY_INVERSED,
  VARIANT_TERTIARY_BLACK,
  VARIANT_TERTIARY_ALERT,
  GRAY_VARIANT,
  VARIANT_PRIMARY_GRADIENT,
  VARIANT_DARK,
] as const;

export type IButtonVariant = (typeof ButtonVariants)[number];

type LinkType = ComponentProps<typeof Link> & {
  as: 'link';
};

type ButtonType = ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: 'button';
};

type ButtonProps = (ButtonType | LinkType) & {
  variant?: IButtonVariant;
  fullWidth?: boolean;
  size?: 'small' | 'normal';
};

const Button = ({
  children,
  fullWidth = false,
  size = 'normal',
  ...props
}: ButtonProps) => {
  const {variant = 'primary', as} = props;

  let className = `${styles.button} `;

  className += styles[variant];

  if (fullWidth) {
    className = `${className} ${styles.fullWidth}`;
  }

  if (as === 'link') {
    className = `${className} ${styles.link}`;
  }

  if (size === 'small') {
    className = `${className} ${styles.small}`;
  }

  if (as === 'link') {
    return (
      <Link {...props} className={props.className || className}>
        {children}
      </Link>
    );
  }

  return (
    <button type="button" {...props} className={props.className || className}>
      {children}
    </button>
  );
};

export default Button;
