import {useQueryClient} from '@tanstack/react-query';
import {createQuery} from 'react-query-kit';
import {fetchUserProfileForCompany} from 'repositories/instill/queries/fetch-user-profile-for-company';
import {dummyUserProfile} from 'ui/@demo-data/user-profile';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {FullUserProfile} from '../../../interfaces/user-profile';
import {RQ_FULL_USER_PROFILE} from './query-keys';

export interface FetchFullUserProfilePayload {
  companyUuid?: string;
}

type Response = FullUserProfile | undefined;
type Payload = FetchFullUserProfilePayload;

export const useFullUserProfile = createQuery<Response, Payload>({
  primaryKey: RQ_FULL_USER_PROFILE,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    if (!variables.companyUuid) {
      return Promise.reject();
    }

    return await fetchUserProfileForCompany(variables.companyUuid);
  }, dummyUserProfile),
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
});

export const useSyncSetFullUserProfile = () => {
  const queryClient = useQueryClient();

  const syncSetFullUserProfile = (
    fullUserProfileVariables: Payload,
    newFullUserProfile: Response | ((prevData: Response) => Response)
  ) => {
    queryClient.setQueryData(
      [RQ_FULL_USER_PROFILE, fullUserProfileVariables],
      newFullUserProfile
    );
  };

  return syncSetFullUserProfile;
};
