import {sanitizeAuthUserFromRaw} from '../../../ui/@sanitizers/auth-user';
import {AuthUser as AuthUserState} from '../../../interfaces/auth-user';
import InstillAPI from '../axios-instance';
import env from 'constants/env';

export class EmailNotVerifiedException extends Error {
  payload: any;

  constructor(message: string, payload: any) {
    super(message);
    this.payload = payload;
  }
}

export interface CreateUser {
  action: 'created';
  createdUser: AuthUserState;
}

export interface LinkUser {
  action: 'linked';
  providers: string[];
}

export interface UpsertAuthUserPayload {
  idToken: string;
  token: string;
}

export async function upsertAuthUser({
  idToken,
  token,
}: UpsertAuthUserPayload): Promise<CreateUser | LinkUser> {
  const payload = {
    id_token: idToken,
    auth0_client_id: env.VITE_AUTH0_CLIENT_ID,
  };

  const response = await InstillAPI.post('/auth-users/', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if ('account_linked' in response.data) {
    return {
      action: 'linked',
      providers: response.data.payload.providers,
    };
  }

  if (!response.data.is_email_verified) {
    const payload = {
      status: 'email_not_verified',
      email: response.data.email,
    };

    throw new EmailNotVerifiedException('Email not verified', payload);
  }

  return {
    action: 'created',
    createdUser: sanitizeAuthUserFromRaw(response.data),
  };
}

export function isUserLinked(
  action: CreateUser | LinkUser
): action is LinkUser {
  return action.action === 'linked';
}

export function isUserCreated(
  action: CreateUser | LinkUser
): action is CreateUser {
  return action.action === 'created';
}
