const getEnv = (key: string): string => {
  // in case of local development
  if (import.meta.env?.[key]) {
    return import.meta.env[key];
  }

  // in case of deployed version environments.js is being used
  return window.env?.[key];
};

const env = {
  VITE_ENVIRONMENT_NAME: (getEnv('VITE_ENVIRONMENT_NAME') || 'development') as
    | 'beta'
    | 'qa'
    | 'development',
  VITE_STRIPE_PUBLISHABLE_KEY: getEnv('VITE_STRIPE_PUBLISHABLE_KEY') || '',
  VITE_SENTRY_DSN: getEnv('VITE_SENTRY_DSN') || '',
  VITE_AMPLITUDE_API_KEY: getEnv('VITE_AMPLITUDE_API_KEY') || '',
  VITE_AMPLITUDE_ENVIRONMENT_NAME:
    getEnv('VITE_AMPLITUDE_ENVIRONMENT_NAME') || '',
  VITE_INSTILL_API_BASE_URL: getEnv('VITE_INSTILL_API_BASE_URL') || '',
  VITE_MICROSOFT_OUTLOOK_CLIENT_ID:
    getEnv('VITE_MICROSOFT_OUTLOOK_CLIENT_ID') || '',
  VITE_CLIENT_BASE_URL: getEnv('VITE_CLIENT_BASE_URL') || '',
  VITE_GOOGLE_CALENDAR_CLIENT_ID:
    getEnv('VITE_GOOGLE_CALENDAR_CLIENT_ID') || '',
  VITE_ZOOM_CLIENT_ID: getEnv('VITE_ZOOM_CLIENT_ID') || '',
  VITE_AUTH0_DOMAIN: getEnv('VITE_AUTH0_DOMAIN') || '',
  VITE_AUTH0_CLIENT_ID: getEnv('VITE_AUTH0_CLIENT_ID') || '',
  VITE_AUTH0_AUDIENCE: getEnv('VITE_AUTH0_AUDIENCE') || '',
  VITE_GOOGLE_CALENDAR_EVENTS_READONLY_SCOPE:
    getEnv('VITE_GOOGLE_CALENDAR_EVENTS_READONLY_SCOPE') || '',
  VITE_WEB_SOCKET_DOMAIN: getEnv('VITE_WEB_SOCKET_DOMAIN') || '',
  VITE_MAPBOX_TOKEN: getEnv('VITE_MAPBOX_TOKEN') || '',
};

export default env;
