import axios, {AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';
import {Navigate, Route, Routes} from 'react-router';
import {useLocation} from 'react-router-dom';
import Spinner from 'ui/@_components/spinner';
import TwoPanePage from 'ui/@_components/two-pane-page';
import lazyPage from 'ui/@hoc/lazy-page';
import {routeNames, routeParams} from '../../constants/route-names';
import {InstillAPIError} from '../../repositories/instill/axios-instance';
import SelectCompanyContextProvider from '../@contexts/company-selector-context';
import OnboardingGuard from './@components/onboarding-guard';
import RequireAuth from './@components/require-auth';
import useFetchAndUpsertUser from './@hooks/use-fetch-and-upsert-user';
import useWhiteLabellingTheme from './@hooks/use-white-labelling-theme';
import CreatingAccount from './@components/creating-account';
import {EmailNotVerifiedException} from 'repositories/instill/mutations/upsert-auth-user';

const AUTH0_EMAIL_VERIFICATION_CALLBACK_URL = '/auth-callback/email-verified';

const Application = lazyPage(() => import('../app/application'));
const SelectCompany = lazyPage(() => import('../select-company'));
const Logout = lazyPage(() => import('../logout'));
const Invite = lazyPage(() => import('../invite'));
const StoryBook = lazyPage(() => import('../@story-book/story-book'));
const RegisterCompany = lazyPage(() => import('../register-company'));
const Candidates = lazyPage(() => import('../candidates'));
const DemoAccount = lazyPage(() => import('../demo-account'));
const AccountLinked = lazyPage(
  () => import('./@components/auth-feedback/@components/account-linked')
);
const AuthFeedback = lazyPage(() => import('./@components/auth-feedback'));
const VerifyYourEmail = lazyPage(() => import('../verify-your-email'));
const PageNotFound = lazyPage(() => import('../page-not-found'));
const MeetingsShowv2 = lazyPage(
  () => import('../app/sense/meetings/show-v2/meetings-show')
);
const CandidateReport = lazyPage(
  () =>
    import(
      '../app/candidate-calibration/job-positions/show/report/@components/main-content'
    )
);
const EmailVerified = lazyPage(() => import('../email-verified'));
const OAuthCallback = lazyPage(() => import('../oauth-callback'));

const ApplicationRoutes = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const {currentAuthUser, isLoading, error, isAccountLinked, userProviders} =
    useFetchAndUpsertUser();

  useWhiteLabellingTheme();

  if (isLoading) {
    return <Spinner fullScreen />;
  }

  if (isAccountLinked) {
    return <AccountLinked providers={userProviders} />;
  }

  if (axios.isAxiosError(error)) {
    const serverError = error as AxiosError<InstillAPIError>;
    return <AuthFeedback errorPayload={serverError.response?.data.payload} />;
  } else if (error instanceof EmailNotVerifiedException) {
    if (!location.pathname.includes(AUTH0_EMAIL_VERIFICATION_CALLBACK_URL)) {
      return <VerifyYourEmail email={error.payload.email} />;
    }
  } else if (error instanceof Error) {
    return <div>{t('error', {message: error.message})}</div>;
  } else if (typeof error === 'string') {
    return <div>{t('error', {message: error})}</div>;
  } else if (error) {
    return <div>{t('error', {message: t('unknown')})}</div>;
  }

  return (
    <div>
      <Routes>
        <Route
          path={`/${routeNames.candidatesRoute}/*`}
          element={<Candidates />}
        />

        <Route element={<RequireAuth user={currentAuthUser} />}>
          <Route
            path="/"
            element={<Navigate replace to={routeNames.applicationRoute} />}
          />

          <Route
            path={`${routeNames.applicationRoute}/*`}
            element={
              <SelectCompanyContextProvider>
                <OnboardingGuard>
                  <CreatingAccount />

                  <Application />
                </OnboardingGuard>
              </SelectCompanyContextProvider>
            }
          />

          <Route path={`/${routeNames.logoutRoute}`} element={<Logout />} />

          {/* As of August 1st, company registration is temporarily disabled. Companies must be created through admin portal */}
          <Route
            path={`/${routeNames.registerCompanyRoute}/`}
            element={
              <TwoPanePage>
                <RegisterCompany />
              </TwoPanePage>
            }
          />

          <Route
            path="/select-company/"
            element={
              <SelectCompanyContextProvider>
                <SelectCompany />
              </SelectCompanyContextProvider>
            }
          />

          <Route
            path="/story-book/*"
            element={
              <SelectCompanyContextProvider>
                <StoryBook />
              </SelectCompanyContextProvider>
            }
          />
        </Route>

        <Route
          path={`/invite/:${routeParams.inviteTokenParam}`}
          element={<Invite />}
        />

        <Route
          path={`/${routeNames.unAuthorizedMeetingRoute}/:${routeParams.meetingIdParam}`}
          element={<MeetingsShowv2 />}
        />

        <Route
          path={`/${routeNames.candidatesRoute}/${routeNames.candidateResult}/:${routeParams.candidateIdParam}`}
          element={<CandidateReport />}
        />

        <Route
          path={`/${routeNames.demoAccountRoute}/*`}
          element={<DemoAccount />}
        />

        <Route
          path={AUTH0_EMAIL_VERIFICATION_CALLBACK_URL}
          element={<EmailVerified />}
        />

        <Route path="*" element={<Navigate replace to="/page-not-found" />} />

        <Route path="/page-not-found" element={<PageNotFound />} />

        <Route
          path={`${routeNames.oAuthCallbackRoute}/*`}
          element={
            <SelectCompanyContextProvider>
              <OAuthCallback />
            </SelectCompanyContextProvider>
          }
        />
      </Routes>
    </div>
  );
};

export default ApplicationRoutes;
