export {};

window.addEventListener('vite:preloadError', (event) => {
  const message = event.payload.message;

  if (
    message.includes('Failed to fetch dynamically imported module') ||
    message.includes('Unable to preload CSS')
  ) {
    event.stopPropagation();

    window.location.reload();
  }
});
