import './init/fallback-local-storage';
import './init/sentry-init';
import './init/handle-dynamic-load-error';
import 'regenerator-runtime/runtime';
import * as amplitude from '@amplitude/analytics-browser';
import {sessionReplayPlugin} from '@amplitude/plugin-session-replay-browser';
import {onlineManager} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import {initReactI18next} from 'react-i18next';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {RecoilRoot} from 'recoil';
import InstillQueryClientProvider from 'ui/@contexts/instill-query-client-provider';
import {ThemeContextProvider} from 'ui/@contexts/theme-context';
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import isLocalHost from 'utils/environment-utils/is-local-host';
import appPackage from '../package.json';
import './index.scss';
import translations from './locales';
import ApplicationRoutes from './ui/application-routes';
import Auth0ProviderWithRedirectCallback from './ui/auth0';
import env from 'constants/env';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

onlineManager.setOnline(true);

i18n.use(initReactI18next).init({
  defaultNS: 'common',
  fallbackLng: 'en',
  lng: 'en',
  resources: translations,
  returnNull: false,
  interpolation: {
    escapeValue: false,
  },
});

if (Boolean(env.VITE_AMPLITUDE_API_KEY)) {
  const appVersion = env.VITE_AMPLITUDE_ENVIRONMENT_NAME
    ? `${env.VITE_AMPLITUDE_ENVIRONMENT_NAME}-${appPackage.version}`
    : appPackage.version;

  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1,
  });

  amplitude.add(sessionReplayTracking);

  amplitude.init(env.VITE_AMPLITUDE_API_KEY, undefined, {
    appVersion: appVersion,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <HelmetProvider>
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback>
            <InstillQueryClientProvider>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <ThemeContextProvider>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    closeOnClick
                    hideProgressBar={false}
                    pauseOnFocusLoss={false}
                    theme="colored"
                    closeButton={false}
                  />

                  <ApplicationRoutes />
                </ThemeContextProvider>
              </QueryParamProvider>

              {isLocalHost && (
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              )}
            </InstillQueryClientProvider>
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </HelmetProvider>
    </RecoilRoot>
  </React.StrictMode>
);
