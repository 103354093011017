import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {Company} from '../../interfaces/company';
import {FullUserProfile} from '../../interfaces/user-profile';
import {SELECTED_COMPANY_UUID_KEY} from '../../constants/local-storage-keys';
import {useCurrentAuthUserState} from '../@atoms/current-auth-user';
import {useCurrentCompanyState} from '../@atoms/current-company';
import {useEffectOnce} from 'react-use';
import {useLocation} from 'react-router-dom';
import {useNavigate, Location} from 'react-router';
import {routeNames} from 'constants/route-names';
import {useFullUserProfile} from 'ui/@hooks/queries/use-full-user-profile';

const SELECT_COMPANY_PATH = '/select-company/';

interface Context {
  onSelectCompany: (company: Company) => Promise<void>;
}

const CompanySelectorContext = createContext<Context | null>(null);

export const useCompanySelector = () => {
  const context = useContext(CompanySelectorContext);
  if (!context) {
    throw new Error(
      'useCompanySelector must be used within CompanySelectorContextProvider'
    );
  }
  return context;
};

const getComponent = (
  location: Location,
  children: ReactNode,
  currentCompany: Company | null,
  currentFullUserProfile: FullUserProfile | null
) => {
  if (location.pathname.includes(SELECT_COMPANY_PATH)) {
    // We need to let the company selector render even if essential variables
    // haven't yet been set.
    return children;
  }

  // When all essential variables have been set, let's render the application.
  const shouldRenderApplication = currentCompany && currentFullUserProfile;
  if (shouldRenderApplication) {
    return children;
  }

  return null;
};

const CompanySelectorContextProvider: FunctionComponent<
  PropsWithChildren<{}>
> = ({children}) => {
  const [currentCompany, setCurrentCompany] = useCurrentCompanyState();
  const [currentAuthUser] = useCurrentAuthUserState();

  const [selectedCompany, setSelectedCompany] = useState<Company>();

  const location = useLocation();
  const navigate = useNavigate();

  const currentFullUserProfile = useFullUserProfile({
    enabled: Boolean(selectedCompany),
    variables: {
      companyUuid: selectedCompany?.uuid,
    },
  });

  const onSelectCompany = useCallback(
    async (company: Company) => {
      if (
        company.uuid === currentCompany?.uuid &&
        currentFullUserProfile.data
      ) {
        return;
      }

      if (!currentCompany || company.uuid !== currentCompany.uuid) {
        setCurrentCompany(company);
        localStorage.setItem(SELECTED_COMPANY_UUID_KEY, company.uuid);
      }

      setSelectedCompany(company);
    },
    [
      setCurrentCompany,
      setSelectedCompany,
      currentCompany,
      currentFullUserProfile,
    ]
  );

  const value = {onSelectCompany, currentFullUserProfile};

  useEffectOnce(() => {
    const companiesCount = currentAuthUser?.companies.length ?? 0;
    const savedCompanyUuid = localStorage.getItem(SELECTED_COMPANY_UUID_KEY);

    if (companiesCount === 0) {
      navigate(`/${routeNames.registerCompanyRoute}/`);
      return;
    }

    if (savedCompanyUuid) {
      const company = currentAuthUser?.companies.find(
        (company) => company.uuid === savedCompanyUuid
      );

      if (company) {
        onSelectCompany(company);
        return;
      }
    }

    if (companiesCount === 1 && currentAuthUser!.companies[0]) {
      onSelectCompany(currentAuthUser!.companies[0]);
      return;
    }

    if (companiesCount > 1) {
      if (!location.pathname.includes(SELECT_COMPANY_PATH)) {
        navigate(SELECT_COMPANY_PATH);
        return;
      }
    }
  });

  const componentToRender = getComponent(
    location,
    children,
    currentCompany,
    currentFullUserProfile.data || null
  );

  return (
    <CompanySelectorContext.Provider value={value}>
      {componentToRender}
    </CompanySelectorContext.Provider>
  );
};

export default CompanySelectorContextProvider;
