import CloseIcon from 'ui/@_components/kit/icons/close.svg?react';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface Props {
  onClick: () => void;
}

const CloseButton: FunctionComponent<Props> = ({onClick}) => {
  const {t} = useTranslation('components');

  return (
    <button
      className={styles.button}
      type="button"
      onClick={onClick}
      aria-label={t(
        'dialog.header.close-button.close-button-accessibility-text'
      )}
    >
      <CloseIcon className={styles.icon} />
    </button>
  );
};

export default CloseButton;
