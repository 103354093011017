export {};

let memoryLocalStorage: Record<string, string> = {};

const fallbackLocalStorage = {
  getItem: (key: string) => {
    return memoryLocalStorage[key] ?? null;
  },
  setItem: (key: string, value: string) => {
    memoryLocalStorage[key] = value;
  },
  removeItem: (key: string) => {
    delete memoryLocalStorage[key];
  },
  clear: () => {
    memoryLocalStorage = {};
  },
  key: (index: number) => {
    return Object.keys(memoryLocalStorage)[index] ?? null;
  },
  length: Object.keys(memoryLocalStorage).length,
};

try {
  if (!window.localStorage) {
    window.localStorage = fallbackLocalStorage;
  }
  // ? If browser throws error in window.localStorage access then it will throw error
} catch (err) {
  window.localStorage = fallbackLocalStorage;
}
