export interface PersonalityProfileRaw extends PersonalityScoreRaw {
  persona: PersonaType;
  persona_content: PersonaContentRaw;
  personal_values: PersonalValuesRaw;
}

export enum PersonaType {
  Diplomat = 'The Harmonious Diplomat',
  Explorer = 'The Explorer',
  Sage = 'The Sage',
  Pathfinder = 'The Pathfinder',
  Maverick = 'The Maverick',
  Valiant = 'The Valiant',
  Mastermind = 'The Mastermind',
  Strategist = 'The Strategist',
  Dreamer = 'The Dreamer',
  Visionary = 'The Visionary',
  Brave = 'The Brave',
  Guardian = 'A Noble Guardian',
  Champion = 'The Champion Ally',
  Enigma = 'The Enigma',
  Pioneer = 'The Pioneer',
  Stalwart = 'The Stalwart',
}

export interface PersonalityProfile extends PersonalityScore {
  persona: PersonaType;
  personaContent: PersonaContent;
  personalValues: PersonalValues;
}

export interface PersonalValuesRaw {
  self_direction: number;
  stimulation: number;
  hedonism: number;
  achievement: number;
  power: number;
  security: number;
  conformity: number;
  tradition: number;
  benevolence: number;
  universalism: number;
  user_personal_values: UserPersonalValueRaw[];
}

export interface PersonalValues {
  selfDirection: number;
  stimulation: number;
  hedonism: number;
  achievement: number;
  power: number;
  security: number;
  conformity: number;
  tradition: number;
  benevolence: number;
  universalism: number;
  userPersonalValues: UserPersonalValue[];
}

export interface UserPersonalValueRaw {
  title: string;
  description: string;
}

export interface UserPersonalValue {
  title: string;
  description: string;
}

export interface PersonalityScoreRaw {
  agreeableness: number;
  conscientiousness: number;
  emotionality: number;
  extroversion: number;
  honesty: number;
  openness: number;
}

export interface PersonalityScore {
  agreeableness: number;
  conscientiousness: number;
  emotionality: number;
  extroversion: number;
  honesty: number;
  openness: number;
}

export interface PersonaContent {
  deepDive: DeepDive;
  description: Description;
  name: string;
  outOfSync: OutOfSync;
  summary: string;
  traits: string[];
  wellFunctioningGroup: WellFunctioningGroup;
}

export interface PersonaContentRaw {
  deep_dive: DeepDive;
  description: Description;
  when_out_of_sync: string;
  summary: string;
  traits: string[];
  when_in_a_well_functioning_group: string;
}

interface Description {
  content: string;
  header: string;
}

interface DeepDive {
  content: string;
  headline: string;
}

interface WellFunctioningGroup {
  content: string;
}

interface OutOfSync {
  content: string;
}
