import {setAuthorizationToken} from '../../../repositories/instill/axios-instance';
import {useAuth0} from '@auth0/auth0-react';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useEffect, useRef, useState} from 'react';
import popStorageKey from '../../../utils/local-storage/pop';
import useDemoAccount from 'ui/@hooks/use-demo-account';
import {useLocation} from 'react-router';
import {routeNames} from 'constants/route-names';
import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import useAnalytics from 'ui/@hooks/use-analytics';
import useUserSignupProvider, {
  SIGNUP_PROVIDERS,
} from 'ui/@hooks/use-user-signup-provider';
import {IS_SIGNUP_TRACKED_STORAGE_KEY} from 'constants/local-storage-keys';
import {
  isUserLinked,
  upsertAuthUser,
} from 'repositories/instill/mutations/upsert-auth-user';

const EMAIL_VERIIFIED_STORAGE_KEY = 'Instill-emailVerified';

function useFetchAndUpsertUser() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown | null>(null);
  const [userProviders, setUserProviders] = useState<string[]>([]);
  const [isAccountLinked, setIsAccountLinked] = useState<boolean>(false);
  const [currentAuthUser, setCurrentAuthUser] = useCurrentAuthUserState();
  const {isDemoAccount, startDemoAccount, demoAccountType} = useDemoAccount();
  const hasExecuted = useRef<boolean>(false);
  const location = useLocation();
  const finalIsDemoAccount =
    isDemoAccount || location.pathname === `/${routeNames.demoAccountRoute}`;

  const {
    isLoading: auth0IsLoading,
    error: auth0Error,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const provider = useUserSignupProvider();

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    async function execute() {
      if (auth0IsLoading || auth0Error) return;

      if (!isAuthenticated) {
        setCurrentAuthUser(null);
        setIsLoading(false);

        return;
      }

      if (currentAuthUser) return;

      try {
        setIsLoading(true);

        const ignoreCache = !!popStorageKey(EMAIL_VERIIFIED_STORAGE_KEY);
        const accessToken = await getAccessTokenSilently({ignoreCache});

        setAuthorizationToken(accessToken);

        const idToken = await getIdTokenClaims();

        if (!idToken) return;

        const response = await upsertAuthUser({
          idToken: idToken.__raw,
          token: accessToken,
        });

        if (!response) {
          return;
        }

        if (isUserLinked(response)) {
          setIsLoading(false);
          setIsAccountLinked(true);
          setUserProviders(response.providers);

          return;
        }

        const isSignupTracked = localStorage.getItem(
          IS_SIGNUP_TRACKED_STORAGE_KEY
        );

        if (
          !response.createdUser.updatedAt &&
          provider &&
          provider !== SIGNUP_PROVIDERS.AUTH &&
          !isSignupTracked
        ) {
          trackEvent({
            eventName: EventName.ONBOARDING.SIGNUP_SSO,
            eventType: EventType.BUTTON_CLICKED,
            eventProperties: {
              email: response.createdUser.email,
              ssoMethod: provider,
            },
          });

          localStorage.setItem(IS_SIGNUP_TRACKED_STORAGE_KEY, 'true');
        }

        const currentUser = response.createdUser;
        setCurrentAuthUser(currentUser);

        setError(null);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
        hasExecuted.current = true;
      }
    }

    if (!hasExecuted.current) {
      if (!finalIsDemoAccount) {
        execute();
      } else {
        setError(null);
        setIsLoading(false);
        if (demoAccountType) {
          startDemoAccount(demoAccountType);
        }
        hasExecuted.current = true;
      }
    }
  }, [
    auth0Error,
    auth0IsLoading,
    currentAuthUser,
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    finalIsDemoAccount,
    isLoading,
    setCurrentAuthUser,
    startDemoAccount,
    demoAccountType,
    provider,
    trackEvent,
  ]);

  return {
    currentAuthUser,
    isLoading,
    error: auth0Error || error,
    isAccountLinked,
    userProviders,
  };
}

export default useFetchAndUpsertUser;
