import {useQueryClient} from '@tanstack/react-query';
import {createQuery} from 'react-query-kit';
import {dummyCompanyData} from 'ui/@demo-data/company';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {Company} from '../../../interfaces/company';
import {
  fetchCompany,
  FetchCompanyPayload,
} from '../../../repositories/instill/queries/fetch-company';
import {RQ_COMPANY} from './query-keys';

type Response = Company | undefined;
type Payload = FetchCompanyPayload;

export const useCompany = createQuery<Response, Payload>({
  primaryKey: RQ_COMPANY,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    if (!variables.companyUuid) {
      return Promise.reject();
    }

    return await fetchCompany(variables.companyUuid);
  }, dummyCompanyData),
  enabled: (_, variables) => !!variables.companyUuid,
});

export const useSyncSetCompany = () => {
  const queryClient = useQueryClient();

  const syncSetCompany = (data: Response, variables: Payload) => {
    queryClient.setQueryData([RQ_COMPANY, variables], data);
  };

  return syncSetCompany;
};
